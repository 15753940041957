

export default {
    props: {
        label: {
            type: Object,
        },
        name: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        modalFilter: {
            type: Boolean,
            default: false,
        },
        design: {
            type: String,
        },
    },
    setup(props: any, context: any) {
        function select(label: any) {
            if (!props.disabled) {
                context.emit('select', label);
            }
        }

        return {
            select,
        };
    },
};
