
import { ref, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import DropdownButton from '@/shared/components/DropdownButton.vue';

export default {
    props: {
        modalSort: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DropdownButton,
    },
    setup(props: any, context: any) {
        const modalSort = ref(props.modalSort);
        const sortObject = ref([
            {
                id: 1,
                name: 'Nieuw in assortiment',
                label: 'nieuw',
                sort_by: 'new',
                sort_dir: 'desc',
                checked: false,
            },
            {
                id: 2,
                label: 'naam',
                name: 'Naam A - Z',
                sort_by: 'name',
                sort_dir: 'asc',
                checked: false,
            },
            {
                id: 3,
                label: 'naam',
                name: 'Naam Z - A',
                sort_by: 'name',
                sort_dir: 'desc',
                checked: false,
            },
            {
                id: 5,
                label: 'prijs',
                name: 'Prijs hoog - laag',
                sort_by: 'price',
                sort_dir: 'desc',
                checked: false,
            },
            {
                id: 6,
                label: 'prijs',
                name: 'Prijs laag - hoog',
                sort_by: 'price',
                sort_dir: 'asc',
                checked: false,
            },
        ]);
        const sortText = computed(() => {
            const item = sortObject.value.find((sortItem: any) => sortItem.checked);
            if (item) {
                if (modalSort.value) {
                    return `${item.label}`;
                }
                return `sorteer op ${item.label}`;
            } 
            return 'sorteren';
        });
        const sortShow = ref(false);
        const store = useStore();

        function sort(value: any) {
            sortShow.value = false;
            const sortString = `&sort_by=${value.sort_by}&sort_dir=${value.sort_dir}`;
            store.commit('SET_ORDER', sortString);
            context.emit('sort');
        }

        onBeforeUnmount(() => {
            store.commit('SET_ORDER', '');
        });

        return {
            sortObject,
            sortShow,
            sort,
            sortText,
        };
    },
};
