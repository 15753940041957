import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDown = _resolveComponent("ChevronDown")!
  const _component_RadioSelect = _resolveComponent("RadioSelect")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-btn position-relative", $props.design])
  }, [
    _createElementVNode("button", {
      id: "sortbtn",
      class: _normalizeClass($setup.showRef ? 'border-red' : ''),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
    }, [
      _createTextVNode(_toDisplayString($setup.textValue) + " ", 1),
      _createVNode(_component_ChevronDown, { class: "icon" })
    ], 2),
    ($setup.showRef)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dropdown position-absolute", {'dropdown-modal': $props.modal}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (label, index) => {
            return (_openBlock(), _createBlock(_component_RadioSelect, {
              key: index,
              checked: label.checked,
              label: label,
              design: "bottom-border",
              onSelect: $setup.selectValue
            }, null, 8, ["checked", "label", "onSelect"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, () => { $setup.toggle(false); }]
  ])
}