

import {
    computed, onMounted, onUnmounted, ref, 
} from 'vue';
import FilterButton from '@/components/Filter/FilterButton.vue';
import FilterMenu from '@/components/Filter/FilterMenu.vue';

export default {
    components: {
        FilterButton,
        FilterMenu,
    },
    props: {
        filterItem: {
            type: Object,
        },
        filterKey: {
            type: Number,
        },
        modalFilter: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Object,
            required: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, context: any) {
        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        const newFilterObject = ref(props.filterItem);
        const mainBool = computed(() => {
            if (width.value < 1200 && props.modalFilter && !newFilterObject.value.mobile) {
                return true;
            }

            if (width.value < 1200 && newFilterObject.value.mobile !== undefined && !props.modalFilter && newFilterObject.value.mobile) {
                return true;
            }

            if (width.value >= 1200 && !props.modalFilter && !newFilterObject.value.mobile) {
                return true;
            }

            return false;
        });

        function resetValues(filterKey: any, except?: any) {
            const filterObject = Object.entries(newFilterObject)
                .find(([key, value]) => key === filterKey);

            if (filterObject instanceof Array && filterObject.length > 1) {
                const values = filterObject[1];
                if (values instanceof Array) {
                    values.forEach((value) => {
                        if (except.id !== value.id) {
                            value.checked = false;
                        }
                    });
                }
            }
        }

        function select(filterItem: any, label: any) {
            label.checked = !label.checked;

            resetValues(filterItem, label);
            if (filterItem.value === label.id) {
                filterItem.value = 0;
            } else {
                filterItem.value = label.id;
            }

            context.emit('filter', label);
        }

        function toggle() {
            if (props.active && props.active.name === newFilterObject.value.name) {
                return;
            }

            if (newFilterObject.value.hasIcon === undefined || newFilterObject.value.hasIcon) {
                newFilterObject.value.show = !newFilterObject.value.show;
                return;
            }

            context.emit('toggleModel');
        }

        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        return {
            newFilterObject,
            select,
            width,
            mainBool,
            toggle,
        };
    },
};
