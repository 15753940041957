import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownButton = _resolveComponent("DropdownButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_component_DropdownButton, {
    items: $setup.sortObject,
    text: $setup.sortText,
    show: $setup.sortShow,
    modal: $props.modalSort,
    onSelect: _cache[0] || (_cache[0] = ($event: any) => ($setup.sort($event)))
  }, null, 8, ["items", "text", "show", "modal"])), [
    [_directive_click_outside, () => $setup.sortShow = false]
  ])
}