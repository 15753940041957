
import {
    onMounted, onUnmounted, ref, computed, 
} from 'vue';
import ChevronDown from '@/assets/icons/chevron-down.svg';
import RadioSelect from '@/shared/components/form/RadioSelect.vue';

export default {
    props: {
        text: {
            required: true,
            type: String,
        },
        design: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false,
        },
        modal: {
            type: Boolean,
            default: false,
        },
        items: {
            required: true,
            type: Array,
        },
    },
    components: {
        RadioSelect,
        ChevronDown,
    },
    setup(props: any, context: any) {
        const itemsObject = ref(props.items);
        const showRef = ref(props.show);
        function selectValue(value: any) {
            itemsObject.value.forEach((item: any) => {
                item.checked = false;
            });
            value.checked = true;

            context.emit('select', value);
            context.emit('buttonClick');
        }

        function toggle(set?: boolean) {
            if (typeof set === 'boolean') {
                showRef.value = set;
                return;
            }

            showRef.value = !showRef.value;
        }

        function truncate(string: string, max: number) {
            return string.length > max ? `${string.slice(0, max - 1)}…` : string;
        }

        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        const textValue = computed(() => {
            const text = props.text;

            if (width.value <= 400) {
                return truncate(text, 16);
            }

            if (width.value <= 992) {
                return truncate(text, 21);
            }

            if (width.value <= 1400) {
                return truncate(text, 36);
            }

            return text;
        });

        return {
            textValue,
            selectValue,
            showRef,
            toggle,
            truncate,
            width,
        };
    },
};
