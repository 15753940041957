
import {
    computed, ref, onMounted, watch, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import ChevronBack from '@/assets/icons/chevron-back-outline.svg';
import FilterApplied from '@/components/Filter/FilterApplied.vue';
import FilterDropdown from '@/components/Filter/FilterDropdown.vue';
import Sort from '@/shared/components/Sort.vue';
import Modal from '@/views/Modal.vue';

export default {
    components: {
        FilterDropdown,
        FilterApplied,
        Sort,
        Modal,
        ChevronBack,
    },
    props: {
        filter: {
            type: Object,
        },
        count: {
            type: Number,
        },
    },
    setup(props: any, context: any) {
        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        const showModal = ref(false);
        const filters = ref([
            {
                name: 'filter options',
                label: 'filter opties',
                items: [],
                hasIcon: false,
                mobile: true,
            },
            {
                name: 'countries',
                label: 'land',
                key: 'country',
                value: 0,
                items: [],
            },
            {
                name: 'regions',
                label: 'streek',
                key: 'region',
                value: 0,
                items: [],
            },
            {
                name: 'estates',
                label: 'wijnhuis',
                key: 'estate',
                value: 0,
                items: [],
            },
            {
                name: 'winetypes',
                label: 'type wijn',
                key: 'winetype',
                value: 0,
                items: [],
            },
            {
                name: 'grapes',
                label: 'druif',
                key: 'grape',
                value: 0,
                items: [],
            },
            {
                name: 'bio/vegan',
                label: 'bio/vegan',
                key: 'bio/vegan',
                value: 0,
                items: [
                    {
                        id: 1,
                        name: 'bio',
                    },
                    {
                        id: 2,
                        name: 'vegan',
                    },
                ],
                hasBio: true,
                hasVegan: true,
            },
        ]);
        const initialFilter = ref(props.filter);
        const store = useStore();
        const appliedFilters = computed(() => Object.entries(filters.value).filter(([, value]) => value.items.filter((val: any) => val.checked).length > 0));
        const activeFilter = computed(() => filters.value.find((filterItem: any) => filterItem.show));
        function addItemsToFilter() {
            filters.value.map((filter: any) => {
                const selected = filter.items.find((item: any) => item.id === filter.value);
                if (filter.name !== 'bio/vegan') {
                    filter.items = [];
                } else {
                    filter.hasVegan = props.filter.metadata.has_vegan;
                    filter.hasBio = props.filter.metadata.has_bio;
                }

                if (initialFilter.value[filter.name]) {
                    Object.assign(filter.items, props.filter[filter.name]);
                }

                if (filter.items.length === 1 && filter.name !== 'bio/vegan') {
                    filter.items.map((item: any) => item.checked = true);
                }

                if (selected && selected.checked) {
                    filter.items.find((item: any) => item.id === selected.id).checked = true;
                }

                return filter;
            });

            const dataLayer = window.dataLayer || [];
            const createFilterEventModel = () => ({
                land: filters.value.find((filter) => filter.name === 'countries').items.filter((item) => item.checked).map((item) => item.name),
                streek: filters.value.find((filter) => filter.name === 'regions').items.filter((item) => item.checked).map((item) => item.name),
                wijnhuis: filters.value.find((filter) => filter.name === 'estates').items.filter((item) => item.checked).map((item) => item.name),
                typeWijn: filters.value.find((filter) => filter.name === 'winetypes').items.filter((item) => item.checked).map((item) => item.name),
                druif: filters.value.find((filter) => filter.name === 'grapes').items.filter((item) => item.checked).map((item) => item.name),
                bioVegan: filters.value.find((filter) => filter.name === 'bio/vegan').items.filter((item) => item.checked).map((item) => item.name),
            });

            dataLayer.push({
                event: 'kwastFilterUsed',
                eventModel: createFilterEventModel(),
            });
        }

        watch(props, () => {
            initialFilter.value = props.filter;
            addItemsToFilter();
        });

        onMounted(() => {
            addItemsToFilter();
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        function toggleAll() {
            filters.value.map((item: any) => {
                item.show = false;
                return item;
            });
        }

        function createFilterString() {
            let filterString = '';

            if (filters && filters.value) {
                filters.value.filter((label: any) => label.value > 0)
                    .forEach((element: any, index: number, array: any) => {
                        if ((array.length - 1) === index) {
                            if (element.key === 'bio/vegan') {
                                element.items.forEach((item: any) => {
                                    if (item.checked) {
                                        filterString += `${item.name}=1&`;
                                    }
                                });
                            } else {
                                filterString += `${element.key}=${element.value}`;
                            }
                        } else if (element.key === 'bio/vegan') {
                            element.items.forEach((item: any) => {
                                if (item.checked) {
                                    filterString += `${item.name}=1&`;
                                }
                            });
                        } else {
                            filterString += `${element.key}=${element.value}&`;
                        }
                    });
            }

            return filterString;
        }

        function reset(type: any) {
            if (type) {
                const typeString = !(type instanceof String) ? type.type : type;

                filters.value.filter((filterItem: any) => filterItem.name === typeString)
                    .map((filterItem: any) => {
                        filterItem.value = filterItem.name === 'bio/vegan' ? 3 : 0;

                        if (filterItem.name !== 'bio/vegan') {
                            filterItem.items = [];
                        } else {
                            filterItem.items = type.items;
                        }

                        return true;
                    });
            } else {
                filters.value.map((filterItem: any) => {
                    filterItem.value = 0;
                    filterItem.items.map((item: any) => item.checked = false);
                    return true;
                });
            }

            context.emit('reset', createFilterString());
        }

        function doFilter() {
            context.emit('filter', createFilterString());
        }

        function searchValueInFilter(filter: any, value: string) {
            filter.items = initialFilter.value[filter.name]
                .filter((item: any) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }

        function toggleModal() {
            showModal.value = !showModal.value;
            store.commit('FILTER_TOGGLE', showModal.value);
        }

        return {
            appliedFilters,
            filters,
            reset,
            doFilter,
            searchValueInFilter,
            width,
            showModal,
            toggleModal,
            activeFilter,
            toggleAll,
        };
    },
};
