import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "name", "value", "checked"]
const _hoisted_2 = { class: "radio-item h-100 d-flex align-items-end" }
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[{'disabled': $props.disabled}, $props.modalFilter ? 'radio-container-modal' : 'radio-container', $props.design], "d-flex align-items-end"]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.select($props.label)), ["prevent"]))
  }, [
    _createElementVNode("input", {
      id: $props.label.id,
      name: $props.name,
      value: $props.label.id,
      type: "radio",
      checked: $props.label.checked
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      ($props.label.name)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: $props.label.name,
            class: "w-100"
          }, _toDisplayString($props.label.name), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "item", {}, undefined, true)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "check" }, null, -1))
  ], 2))
}