
import ChevronDown from '@/assets/icons/chevron-down.svg';
import ChevronForward from '@/assets/icons/chevron-forward.svg';

export default {
    components: {
        ChevronDown,
        ChevronForward,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        hasIcon: {
            type: Boolean,
            default: true,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {};
    },
};
