import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!

  return ($setup.mainBool && (!$props.active || $props.active.name === $props.filterItem.name))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["d-flex justify-content-start", $props.modalFilter ? 'align-items-start menu-item-modal' : 'align-items-center menu-item flex-grow-1'])
      }, [
        _createVNode(_component_FilterButton, {
          label: $setup.newFilterObject.label,
          hasIcon: $props.filterItem.hasIcon === undefined && (!$props.isMobile || !$props.active),
          class: _normalizeClass($props.active && $props.filterItem.name === $props.active.name ? 'active' : ''),
          isMobile: $props.isMobile,
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggle()))
        }, null, 8, ["label", "hasIcon", "class", "isMobile"]),
        _createVNode(_component_FilterMenu, {
          filterLabel: $props.filterKey,
          filterItem: $setup.newFilterObject,
          modalFilter: $props.modalFilter,
          onSelect: _cache[1] || (_cache[1] = ($event: any) => ($setup.select($props.filterItem, $event))),
          onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('search', $event)))
        }, null, 8, ["filterLabel", "filterItem", "modalFilter"])
      ], 2))
    : _createCommentVNode("", true)
}