import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronForward = _resolveComponent("ChevronForward")!
  const _component_ChevronDown = _resolveComponent("ChevronDown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "menu-link d-flex align-items-center flex-shrink-0 w-100",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('buttonClick')), ["prevent"]))
  }, [
    _createTextVNode(_toDisplayString($props.label) + " ", 1),
    ($props.hasIcon && $props.isMobile)
      ? (_openBlock(), _createBlock(_component_ChevronForward, {
          key: 0,
          class: "icon"
        }))
      : _createCommentVNode("", true),
    ($props.hasIcon && !$props.isMobile)
      ? (_openBlock(), _createBlock(_component_ChevronDown, {
          key: 1,
          class: "icon"
        }))
      : _createCommentVNode("", true)
  ]))
}