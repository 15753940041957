import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "d-flex flex-row menu justify-content-evenly align-items-center" }
const _hoisted_3 = { class: "w-100 position-relative mobile-filter d-flex flex-column" }
const _hoisted_4 = { class: "d-flex flex-row align-items-center justify-content-between topbar" }
const _hoisted_5 = {
  key: 0,
  class: "text-start ddin"
}
const _hoisted_6 = {
  key: 1,
  class: "text-start reset-modal"
}
const _hoisted_7 = { class: "text-end reset-modal" }
const _hoisted_8 = { class: "selected-filters d-flex flex-column justify-content-end" }
const _hoisted_9 = { class: "d-flex flex-row flex-wrap justify-content-start" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_Sort = _resolveComponent("Sort")!
  const _component_FilterApplied = _resolveComponent("FilterApplied")!
  const _component_ChevronBack = _resolveComponent("ChevronBack")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filters, (filterItem, filterKey) => {
        return _withDirectives((_openBlock(), _createBlock(_component_FilterDropdown, {
          key: filterItem.label,
          filterItem: filterItem,
          filterKey: filterKey,
          onFilter: $setup.doFilter,
          onToggleModel: $setup.toggleModal,
          onSearch: ($event: any) => ($setup.searchValueInFilter(filterItem, $event))
        }, null, 8, ["filterItem", "filterKey", "onFilter", "onToggleModel", "onSearch"])), [
          [_directive_click_outside, () => filterItem.show = false]
        ])
      }), 128))
    ]),
    ($setup.width < 1200)
      ? (_openBlock(), _createBlock(_component_Sort, {
          key: 0,
          class: _normalizeClass({'float-start': $setup.width <= 1200}),
          modalSort: $setup.width < 1200,
          onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort')))
        }, null, 8, ["class", "modalSort"]))
      : _createCommentVNode("", true),
    _createVNode(_component_FilterApplied, {
      "applied-filters": $setup.appliedFilters,
      count: $props.count,
      class: _normalizeClass({'clear': $setup.width <= 1200}),
      onReset: _cache[1] || (_cache[1] = ($event: any) => ($setup.reset($event))),
      onSort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sort')))
    }, null, 8, ["applied-filters", "count", "class"]),
    ($setup.showModal)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "#modal"
        }, [
          _createVNode(_component_Modal, {
            fullscreen: true,
            onHide: $setup.toggleModal
          }, {
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (!$setup.activeFilter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Filters "))
                    : _createCommentVNode("", true),
                  ($setup.activeFilter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_ChevronBack, { class: "chevron" }),
                        _createElementVNode("u", {
                          class: "clickable",
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.toggleAll && $setup.toggleAll(...args)))
                        }, " Terug ")
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("u", {
                      class: "clickable",
                      onClick: _cache[4] || (_cache[4] = () => {
                                        $setup.reset();
                                        $setup.toggleModal(); 
                                    })
                    }, " Reset alle ")
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filters, (filterItem, filterKey) => {
                  return (_openBlock(), _createBlock(_component_FilterDropdown, {
                    key: filterKey,
                    filterItem: filterItem,
                    filterKey: filterKey,
                    modalFilter: true,
                    isMobile: true,
                    active: $setup.activeFilter,
                    onFilter: $setup.doFilter,
                    onToggleModel: $setup.toggleModal,
                    onSearch: ($event: any) => ($setup.searchValueInFilter(filterItem, $event))
                  }, null, 8, ["filterItem", "filterKey", "active", "onFilter", "onToggleModel", "onSearch"]))
                }), 128)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", null, " Geselecteerde filters (" + _toDisplayString($setup.appliedFilters.length) + "): ", 1),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.appliedFilters, (filterItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: filterItem,
                        class: "me-2"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterItem[1].items.filter((item) => item.checked), (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.id,
                            class: "d-flex align-items-center pill-modal",
                            onClick: ($event: any) => (_ctx.resetItem(filterItem, item))
                          }, _toDisplayString(item.name), 9, _hoisted_10))
                        }), 128))
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("button", {
                    class: "w-100 modal-filter-button",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.toggleModal && $setup.toggleModal(...args)))
                  }, " bekijk " + _toDisplayString($props.count) + " resultaten ", 1)
                ])
              ])
            ]),
            backdrop: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "modal-backdrop show" }, null, -1)
            ])),
            _: 1
          }, 8, ["onHide"])
        ]))
      : _createCommentVNode("", true)
  ]))
}