import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "divider flex-grow-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_ReloadIcon = _resolveComponent("ReloadIcon")!
  const _component_Sort = _resolveComponent("Sort")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["filter-apply d-flex flex-row align-items-center", $setup.width < 1200 ? 'flex-wrap justify-content-start' : 'justify-content-center'])
    }, [
      _createElementVNode("h4", null, _toDisplayString($props.count) + " " + _toDisplayString($props.count > 1 ? 'wijnen': 'wijn'), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.appliedFilters, (filterItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["d-flex flex-row align-items-center justify-content-center", {'my-1': $setup.width < 1200}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterItem[1].items.filter((item) => item.checked), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "d-flex align-items-center pill",
              onClick: ($event: any) => ($setup.resetItem(filterItem, item))
            }, [
              _createTextVNode(_toDisplayString(item.name) + " ", 1),
              _createVNode(_component_CloseIcon, { class: "icon" })
            ], 8, _hoisted_2))
          }), 128))
        ], 2))
      }), 128)),
      ($props.appliedFilters.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "pill white d-flex flex-row align-items-center justify-content-center",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reset')))
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" Reset ")),
            _createVNode(_component_ReloadIcon, {
              class: "icon",
              style: {"fill":"black"}
            })
          ]))
        : _createCommentVNode("", true),
      ($setup.width >= 1200)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      ($setup.width >= 1200)
        ? (_openBlock(), _createBlock(_component_Sort, {
            key: 2,
            onSort: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('sort')))
          }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}