
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import SearchIcon from '@/assets/icons/search-outline.svg';
import RadioSelect from '@/shared/components/form/RadioSelect.vue';
import TextInput from '@/shared/components/form/TextInput.vue';

export default {
    components: {
        TextInput,
        RadioSelect,
        SearchIcon,
    },
    props: {
        filterItem: {
            type: Object,
        },
        filterLabel: {
            type: Number,
        },
        name: {
            type: String,
            default: '',
        },
        modalFilter: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const searchRef = ref('');
        const showMoreRef = ref(false);
        const store = useStore();
        const searched = computed(() => store.getters.filter);

        // Reset searchfield on select
        watch(searched, () => {
            searchRef.value = '';
        });

        function showMore(target: any) {
            showMoreRef.value = !showMoreRef.value;
            target.parentNode.previousSibling.scrollTop = 0;
        }

        return {
            showMore,
            showMoreRef,
            searchRef,
        };
    },
};
