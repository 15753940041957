import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_RadioSelect = _resolveComponent("RadioSelect")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.modalFilter ? 'menu-dropdown-modal' : 'menu-dropdown')
  }, [
    (!$props.modalFilter)
      ? (_openBlock(), _createBlock(_component_TextInput, {
          key: 0,
          modelValue: $setup.searchRef,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.searchRef) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search', $setup.searchRef)))
          ]
        }, {
          "icon-right": _withCtx(() => [
            _createVNode(_component_SearchIcon, { class: "search-icon" })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["menu-dropdown-list d-flex flex-row flex-wrap justify-content-between", {
                'big': $setup.showMoreRef,
            }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filterItem.items, (label) => {
        return (_openBlock(), _createBlock(_component_RadioSelect, {
          key: label.id,
          name: $props.name,
          disabled: label.name === 'bio' ? !$props.filterItem.hasBio : label.name === 'vegan' ? !$props.filterItem.hasVegan : false,
          label: label,
          modalFilter: $props.modalFilter,
          onSelect: ($event: any) => (_ctx.$emit('select', label))
        }, null, 8, ["name", "disabled", "label", "modalFilter", "onSelect"]))
      }), 128))
    ], 2),
    ($props.filterItem && $props.filterItem.items.length > 6 && !$props.modalFilter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "show-more-btn text-start",
            href: "#",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.showMore($event.target)), ["prevent"]))
          }, " Toon " + _toDisplayString($setup.showMoreRef ? 'minder' : 'meer'), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_vShow, $props.filterItem.show]
  ])
}