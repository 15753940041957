
import {
    ref, computed, onMounted, onUnmounted, 
} from 'vue';
import CloseIcon from '@/assets/icons/close-outline.svg';
import ReloadIcon from '@/assets/icons/reload-outline.svg';
import Sort from '@/shared/components/Sort.vue';

export default {
    components: {
        Sort,
        CloseIcon,
        ReloadIcon,
    },
    props: {
        appliedFilters: {
            required: false,
        },
        count: {
            type: Number,
        },
    },
    setup(props: any, context: any) {
        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        const checkedItems = computed(() => props.appliedFilters
            .map((item: any) => item[1].items
                .filter((appliedItem: any) => appliedItem.checked)));

        function resetItem(filterItem: any, item: any) {
            item.checked = false;
            context.emit('reset', { type: filterItem[1].name, items: filterItem[1].items });
        }

        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        return {
            width,
            resetItem,
            checkedItems,
        };
    },
};
